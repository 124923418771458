body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.post-user-img{
 width: 100%;
  font-size: 38px !important;
}
.user-profile-img{
  height: 100px; 
  margin: 2rem;
} 

.tab-content{
  margin-top: 1rem;
} 

.tab-content .input-group{ 
  margin-bottom: 1rem;

}

  